<template>
  <div class="Box">
    <div class="leftBox">
      <textTitle text="行业新闻" />
      <!-- <div @click="goparticulars">
          <detail v-for="(item, index) in newsList" :key="index" />
      </div> -->
      <div class="newsList">
        <div class="detailBox" v-for="(item, index) in newsList" :key="index"  @click="goparticulars(item)">
          <div class="imgBox">
            <img class="img" :src="item.mainPicRealPath" />
            <div class="text">
              <h3>{{item.title}}</h3>
              <p v-html="item.content"></p>
              <span class="time">{{item.publishDate}} <span class="el-icon-view"> {{item.view}}</span></span>
            </div>
          </div>
        </div>
        <el-pagination class="pagination"  :current-page.sync="paging.pageNum" :page-size="paging.pageSize" @current-change="handleCurrentChange"
          background
          layout="prev, pager, next"
          :total="paging.total">
        </el-pagination>
      </div>
    </div>
    <sidebar />
  </div>
</template>
<script>
import textTitle from "@/components/headTail/textTitle";
import sidebar from "@/components/sidebar/sidebar";
import { homeService } from "@/api"
import moment from "moment"

export default {
  components: {
    textTitle,
    sidebar,
  },
  data() {
    return {
      newsList: [],
      paging: {
        pageNum: 1,
        pageSize: 4,
        total: 4
      }
    }
  },
  methods: {
    goparticulars(item) {
     let detailId= item.id + ".html"
      this.$router.push({name: "particulars",params:{ id: detailId, ifjournalism: 2}})
    },
    /* 新闻列表 */
    getNewsList() {
      let d = {
            type: 1,
            pageNum: this.paging.pageNum,
            pageSize: this.paging.pageSize
        }
      homeService.getNewsList(d).then(res => {
        res.list.forEach(ele => {
            ele.publishDate = moment(ele.publishDate).format("YYYY-MM-DD")
            ele.content = ele.content.replace(/<img\b[^>]*>/, "");  

        });
        this.paging.total = Number(res.total)
        this.newsList = res.list
      })
    },
    handleCurrentChange(val) {
      this.getNewsList()
    },
  },
  created() {
    this.getNewsList()
  }
};
</script>
<style lang="scss" scoped>

.newsList{
  height: 550px;
  overflow: auto;
  position: relative;
}
.newsList .el-pagination{
  text-align: center;
  margin-top: 20px;
  position:absolute;
  bottom: 0;
  left: 40%;
}
.detailBox {
  margin-top: 30px;
  margin-left: 20px;
  cursor: pointer;
  h3 {
    font-size: 14px;
    padding-bottom: 4px;
  }
  .imgBox {
    overflow: hidden;
    .img {
      float: left;
      width: 150px;
      height: 100px;
    }
    .text {
      float: left;
      width: 630px;
      height: 100px;
      margin-left: 20px;
      position: relative;
      p {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        line-height: 20px;
        overflow: hidden;
        color: #999;
        font-size: 14px;
      }
      .time {
        display: block;
        margin-top: 20px;
        position: absolute;
        bottom: 0;
        width: 100%;
        span{
          float: right;
        }
      }
    }
  }
}
</style>
<style  scoped>
.detailBox .imgBox >>> span{
  font-size: 14px !important;
}
</style>
